<template>
  <div class="rsf-select-wrapper relative col-12 lg-hide block mb-40">
    <svg
      class="z2"
      :style="`transform: rotate(180deg) translateY(50%)`"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M17 15L12 10L7 15"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <select
      class="rsf-select pointer h5 bg-color-white color-black"
      style="border-color: var(--rsf-2022-color-12) !important"
      @change="updateActiveCategory(Number($event.target.value))"
      v-model="displayedCategory"
    >
      <option
        v-for="(category, index) in categories"
        :value="index"
        v-html="category.name"
      ></option>
    </select>
  </div>
</template>

<script>
export default {
  name: "categorySelect",
  props: {
    categories: Array,
    activeCategory: Number,
    updateActiveCategory: Function,
  },
  data() {
    return {
      displayedCategory: 0,
    };
  },
  watch: {
    activeCategory(newVal, oldVal) {
      if (newVal != oldVal) {
        if (newVal == -1) {
          this.displayedCategory = 0;
        } else {
          this.displayedCategory = newVal;
        }
      }
    },
  },
};
</script>

<style scoped>
.rsf-select-wrapper {
  margin: 0 auto;
  max-width: 600px;
  margin-bottom: 2rem;
}
.rsf-select-wrapper.mb-40 {
  margin-bottom: 40px;
}
</style>
