<template>
  <div :class="`blog-tag-pill open-sans-regular h6 pointer h-fit-content nowrap ${activeTag.slug == tag.slug  ? 'bg-color-16 color-black' : 'bg-color-white color-14' }`" style="border-radius: 2px; padding: 10px 16px" @click="updateActiveTag(tag);">
    {{ tag.name }}
  </div>
</template>

<script>

export default {
  name: 'tagItem',
  props: {
    tag: Object,
    activeTag: Object,
    updateActiveTag: Function,
    fetchBlogPosts: Function
  }
}
</script>

<style scoped>
@media(hover: hover) and (pointer: fine){
  .blog-tag-pill:hover{
    color: var(--rsf-2022-color-1);
  }
}
</style>
