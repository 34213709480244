<template>
  <div class="lg-flex hide justify-center">
    <tabItem v-for="(category, index) in categories" :key="index" :category="category" :categoryIndex="index" :updateActiveCategory="updateActiveCategory" :activeCategory="activeCategory"/>
  </div>
</template>

<script>

import tabItem from './tabItem.vue'

export default {
  name: 'tabSwitch',
  props: {
    categories: Array,
    updateActiveCategory: Function, 
    activeCategory: Number
  },
  components: {
    tabItem
  }
}
</script>

<style scoped>

</style>
