import { render, staticRenderFns } from "./categorySelect.vue?vue&type=template&id=15af6720&scoped=true"
import script from "./categorySelect.vue?vue&type=script&lang=js"
export * from "./categorySelect.vue?vue&type=script&lang=js"
import style0 from "./categorySelect.vue?vue&type=style&index=0&id=15af6720&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15af6720",
  null
  
)

export default component.exports