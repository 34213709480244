<template>
  <div class="pointer" @click="updateActiveCategory(categoryIndex)">
    <div class="gridbox mx-auto mb1" style="min-height: 20px">
      <img
        v-show="
          activeCategory == categoryIndex ||
          (activeCategory == -1 && categoryIndex == 0)
        "
        class="mx-auto block gridbox-elemnent"
        :src="`https://blog.apps.redseafish.com/static-assets/${category.slug}-b.svg`"
      />
      <img
        v-show="
          activeCategory != categoryIndex &&
          !(activeCategory == -1 && categoryIndex == 0)
        "
        class="mx-auto block gridbox-elemnent"
        :src="`https://blog.apps.redseafish.com/static-assets/${category.slug}.svg`"
      />
    </div>
    <span
      :class="`h6 px3 block ${
        activeCategory == -1 && categoryIndex == 0
          ? 'open-sans-bold color-1'
          : activeCategory == categoryIndex
          ? 'open-sans-bold color-1'
          : 'open-sans-regular color-14'
      }`"
      v-html="category.name"
    ></span>
    <div
      :class="`category-tab-indicator h1px bg-color-12 mt1 ${
        activeCategory == -1 && categoryIndex == 0
          ? 'active'
          : activeCategory == categoryIndex
          ? 'active'
          : ''
      }`"
    ></div>
  </div>
</template>

<script>
export default {
  name: "tabItem",
  props: {
    category: Object,
    updateActiveCategory: Function,
    activeCategory: Number,
    categoryIndex: Number,
  },
};
</script>

<style scoped>
.category-tab-indicator {
  padding: 1px 0;
  background-clip: content-box;
}
.category-tab-indicator.active {
  background-color: var(--rsf-2022-color-1);
  height: 3px;
  padding: 0;
}
</style>
