var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a',{class:`${
    _vm.bigHeading ? 'md-flex block' : 'flex'
  } bg-color-white overflow-hidden blog-item-card rsf-card-interaction-animation`,staticStyle:{"border-radius":"12px"},attrs:{"href":_vm.blogPostLink}},[_c('div',{class:`relative bg-color-12 ${
      _vm.bigHeading
        ? 'blog-item-big-img md-col-6 col-12'
        : 'blog-item-blog-img col-6'
    }`},[_c('img',{staticClass:"absolute col-12 h100 cover blog-feature-image",attrs:{"onload":"this.style.opacity=1","src":_vm.blogItem.feature_image.image_path,"loading":"lazy"}})]),_c('div',{class:`md-col-8 col-12 border-box ${
      _vm.bigHeading ? 'big-card-padding' : 'blog-card-padding'
    }`},[_c('div',{staticClass:"flex items-center flex-wrap gap1"},_vm._l((_vm.blogItem.blog_categories),function(category,index){return _c('div',{key:index,class:`flex items-center gap1 pointer blog-item-category-tag ${
          index > 0 ? 'md-flex hide' : ''
        }`,on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.updateActiveCategory(category)}}},[_c('img',{staticStyle:{"width":"20px"},attrs:{"src":`https://blog.apps.redseafish.com/static-assets/${category.term_slug}-b.svg`,"loading":"lazy"}}),_c('span',{staticClass:"open-sans-bold color-1 h6",domProps:{"innerHTML":_vm._s(category.term_name)}})])}),0),_c('h3',{class:`helvetica-bold ${
        _vm.bigHeading ? 'h1' : 'md-h2 h4'
      } color-black mt2 md-mb1 mb0`,domProps:{"innerHTML":_vm._s(_vm.blogItem.post_title)}}),_c('p',{staticClass:"open-sans-regular h5 color-black md-block hide",domProps:{"innerHTML":_vm._s(_vm.blogItem.post_excerpt)}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }