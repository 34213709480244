var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pointer",on:{"click":function($event){return _vm.updateActiveCategory(_vm.categoryIndex)}}},[_c('div',{staticClass:"gridbox mx-auto mb1",staticStyle:{"min-height":"20px"}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.activeCategory == _vm.categoryIndex ||
        (_vm.activeCategory == -1 && _vm.categoryIndex == 0)
      ),expression:"\n        activeCategory == categoryIndex ||\n        (activeCategory == -1 && categoryIndex == 0)\n      "}],staticClass:"mx-auto block gridbox-elemnent",attrs:{"src":`https://blog.apps.redseafish.com/static-assets/${_vm.category.slug}-b.svg`}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.activeCategory != _vm.categoryIndex &&
        !(_vm.activeCategory == -1 && _vm.categoryIndex == 0)
      ),expression:"\n        activeCategory != categoryIndex &&\n        !(activeCategory == -1 && categoryIndex == 0)\n      "}],staticClass:"mx-auto block gridbox-elemnent",attrs:{"src":`https://blog.apps.redseafish.com/static-assets/${_vm.category.slug}.svg`}})]),_c('span',{class:`h6 px3 block ${
      _vm.activeCategory == -1 && _vm.categoryIndex == 0
        ? 'open-sans-bold color-1'
        : _vm.activeCategory == _vm.categoryIndex
        ? 'open-sans-bold color-1'
        : 'open-sans-regular color-14'
    }`,domProps:{"innerHTML":_vm._s(_vm.category.name)}}),_c('div',{class:`category-tab-indicator h1px bg-color-12 mt1 ${
      _vm.activeCategory == -1 && _vm.categoryIndex == 0
        ? 'active'
        : _vm.activeCategory == _vm.categoryIndex
        ? 'active'
        : ''
    }`})])
}
var staticRenderFns = []

export { render, staticRenderFns }