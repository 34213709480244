import App from './App.vue'


export default [
    { 
        path: '/:lang', 
        component: App,
        name: 'app'
    },
    { 
        path: '/:lang/category=:category', 
        component: App,
        name: 'app-cat'
    },
    { 
        path: '/:lang/category=:category/tag=:tag', 
        component: App,
        name: 'app-cat-tag'
    },
    { 
        path: '/:lang/search=:search', 
        component: App,
        name: 'app-search'
    },
    { 
        path: '*',
        redirect: '/en' 
    },
]